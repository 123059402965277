import styled from "styled-components";

import { FourZeroFourStyledProps as Props } from "./FourZeroFour.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { primary } = variables.fonts;

const FourZeroFourStyled = styled.div<Props>`
  font-family: ${primary};
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));

  @media (max-width: ${tablet}px) {
    padding-top: calc(
      var(--sizes-navbar-height-mobile) - env(safe-area-inset-top)
    );
  }

  .FourZeroFour {
    &__header {
      grid-column: fullBleed;
    }

    &__main {
      grid-column: content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      width: 11.2rem;
      height: 11.2rem;
      border-radius: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--palette-white);
    }

    &__info {
      color: var(--palette-black-s0-l10);
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 2.8rem;
      padding-top: 1.6rem;
    }

    &__description {
      max-width: 26.4rem;
      color: var(--palette-gray-s0-l35);
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1.2rem 0;
      text-align: center;
    }

    &__button {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 1.6rem 8rem;
    }

    &__footer {
      grid-column: fullBleed;
    }
  }
`;

export default FourZeroFourStyled;
