import ErrorSVG from "images/404/four-zero-four.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./FourZeroFour.styles";
import { FourZeroFourProps as Props } from "./FourZeroFour.types";
import Button from "components/global/Button/Button";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import useI18n from "hooks/useI18n";

const FourZeroFour: React.FC<Props> = props => {
  const router = useRouter();
  const t = useI18n();

  return (
    <Styles className="FourZeroFour">
      <header className="FourZeroFour__header">
        <Navbar showBrand />
      </header>
      <main className="FourZeroFour__main">
        <div className="FourZeroFour__icon">
          <ErrorSVG />
        </div>
        <p className="FourZeroFour__info">{t.notFound.title}</p>
        <p className="FourZeroFour__description">{t.notFound.description}</p>
        <Button onClick={() => router.push("/")}>{t.notFound.back}</Button>
      </main>
      <Footer className="FourZeroFour__footer" />
    </Styles>
  );
};

FourZeroFour.defaultProps = {};

export default FourZeroFour;
